<template>
  <div>
    <v-layout>
      <v-btn icon class="primary--text mt-1 mr-2" 
        :to="`/${projectId}/${namespaceId}/applications/${applicationId}/allocations`"
        router 
        exact
      >
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Application Logs</page-title>

      <div class="ml-4 mt-1 mb-2">
        <v-btn class="primary--text mr-3" text>
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>
      </div>
      <div class="ml-4 mt-1 mb-2">
       <v-select
          :items="this.containers"
          @input="selectContainer"
          v-model="selectedContainer"
          dense
          item-text="name"
          item-value="id"
          label="Container"
        ></v-select>
      </div>
    </v-layout>
    <v-layout>
      <page-subtitle>View application '{{ applicationId }}' on device '{{ deviceId }}' logs.</page-subtitle>
    </v-layout>

    <v-layout class="mb-3" wrap>
      <v-flex lg12 md12 sm12 pr-2>
        <v-card flat>
          <v-card-title>
            {{ deviceId }}
          </v-card-title>
          <v-card-text>
            <div ref="terminal" class="synpse-xterm"></div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import PageSubtitle from '../PageSubtitle'
import PageTitle from '../PageTitle'

import { Terminal } from "xterm"
import * as fit from "xterm/lib/addons/fit/fit"
import * as webLinks from "xterm/lib/addons/webLinks/webLinks"
import * as search from "xterm/lib/addons/search/search"
import "xterm/dist/xterm.css"

let bindTerminal = (term, websocket) => {
  term.socket = websocket
  let handleWebSocketMessage = function (event) {
    if (event.data instanceof Blob) {
        let reader = new FileReader()
        reader.onload = () => {
            term.write(reader.result.replace('\n', '\n\r'))
        };
        reader.readAsText(event.data);
    }
  };

  websocket.onmessage = handleWebSocketMessage;
  let heartBeatTimer = setInterval(function () {
    websocket.send(JSON.stringify({ type: "heartbeat", data: "" }))
  }, 20 * 1000);
  websocket.addEventListener("close", function () {
    websocket.removeEventListener("message", handleWebSocketMessage)
    delete term.socket
    clearInterval(heartBeatTimer)
  });
};

export default {
  components: {
    PageSubtitle,
    PageTitle,
  },

  data() {
    return {
      ws: null,
      term: null,
      selectedContainer: "",
      containers:[],
    }
  },

  computed: {
    projectId() {
      return this.$route.params.project
    },
    namespaceId() {
      return this.$route.params.namespace
    },
    applicationId() {
      return this.$route.params.application
    },
    application() {
      return this.$store.state.application.application
    },
    deviceId() {
      return this.$route.params.device
    },
    loading() {
      return this.$store.state.application.loading
    },
    wsBase() {
      return `${window.location.protocol === "https:" ? "wss://" : "ws://"}${window.location.host}`;
    },
    wsUrl() {
      let token = this.$store.getters.jwt
      return `${this.wsBase}/api/projects/${this.projectId}/namespaces/${this.namespaceId}/applications/${this.applicationId}/logs/${this.selectedContainer}?device=${this.deviceId}&follow=true&_t=${token}&tail=500`
    }
  },

  mounted() {
    this.fetchApplication()
  },

  beforeDestroy() {
    // Terminate connection
    this.doClose()
  },

  methods: {
    selectContainer(name){
      this.selectedContainer= name
      this.clear()
      this.doOpened()
    },
    fetchApplication() {
      let q = {
        projectId: this.projectId,
        namespaceId: this.namespaceId,
        applicationId: this.applicationId
      }
      this.$store.dispatch('GetApplication', q).then(() => {
      this.containers = this.application.spec.containers
      // default to first container fr first choise
      this.selectedContainer= this.application.spec.containers[0].name
      this.doOpened()
      })
    },
    doOpened() {
      Terminal.applyAddon(fit)
      Terminal.applyAddon(webLinks)
      Terminal.applyAddon(search)

      this.term = new Terminal({
        rows: 25,
        fontSize: 18,
        cursorBlink: false,
        cursorStyle: 'bar',
        bellStyle: "sound",
      })
      this.term.open(this.$refs.terminal)
      this.term.fit() // first resizing

      this.term.webLinksInit(this.doLink)
      // term.on("resize", this.onTerminalResize);
      window.addEventListener("resize", this.onWindowResize)
      this.ws = new WebSocket(this.wsUrl)
      this.ws.binaryType = 'blob'

      this.ws.onclose = () => {
          this.doClose()
      };
      bindTerminal(this.term, this.ws)
    },
    doClose() {
      window.removeEventListener("resize", this.onWindowResize)
      if (this.ws) {
        this.ws.close()
      }
      if (this.term) {
        this.term.dispose()
      }
    },
    clear(){
      if (this.term) {
        this.term.dispose()
      }
    }
  }
}

</script>
